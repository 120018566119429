



















import { Vue, Component, Prop, Emit } from 'vue-property-decorator';

@Component({})
export default class BindChannelInfo extends Vue {
  @Prop() bindedChannelId!: number

  @Emit()
  onUnbind() {
    return null
  }
}
