var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bot-guard-handler-module-view"},[_c('page-title',{attrs:{"title":_vm.$t(_vm.module.model.titleKey),"subtitle":_vm.$te(_vm.module.model.descriptionKey) ? _vm.$t(_vm.module.model.descriptionKey) : null,"help-message":_vm.$te(_vm.module.model.pageHelpMessageKey) ? _vm.$t(_vm.module.model.pageHelpMessageKey) : null},scopedSlots:_vm._u([{key:"custom-button",fn:function(){return [(_vm.hasBackButton)?_c('go-back-button',{on:{"go-back":_vm.goBack}}):_vm._e(),(_vm.isSaveButtonVisible)?_c('save-module-button',{on:{"save-module":_vm.saveModule}}):_vm._e()]},proxy:true}])}),(_vm.module.model.params.binded_channel_id !== 0)?[_c('module-config-settings-wrapper',{attrs:{"has-conditions":_vm.module.model.config.conditionsExists}},[_c('module-config-settings',{model:{value:(_vm.module.model.config),callback:function ($$v) {_vm.$set(_vm.module.model, "config", $$v)},expression:"module.model.config"}})],1),_c('a-card',{staticClass:"mt-5",attrs:{"title":_vm.$t('field_facecontrol_bind_channel_title')}},[_c('bind-channel-info',{attrs:{"binded-channel-id":_vm.module.model.params.binded_channel_id},on:{"on-unbind":_vm.unbind}})],1),_c('a-card',{staticClass:"mt-5"},[_c('punish-action-setting',{attrs:{"title-key":"ban_action"},model:{value:(_vm.module.model.params.ban_action),callback:function ($$v) {_vm.$set(_vm.module.model.params, "ban_action", $$v)},expression:"module.model.params.ban_action"}})],1),_c('a-card',{staticClass:"mt-5"},[_c('switch-input',{staticClass:"mt-0 mb-0",attrs:{"setup":{
          'func': _vm.newConfigInputSetup,
          'args': {
            'model': _vm.module.model.params,
            'key': 'journal',
            'prefix': 'enter_binded_channel_handler_module_',
            'hasAnchor': true,
          }
        }}})],1)]:_c('a-card',[(_vm.tariffTag)?_c('not-available-options-overlay',{attrs:{"tags":_vm.tariffTag,"with-button":false,"description":_vm.$t('brand_tag_help_view_text', {id: _vm.$store.getters.chatId})}}):[_c('a-alert',[_c('div',{attrs:{"slot":"message"},domProps:{"innerHTML":_vm._s(_vm.$t('facecontrol_bind_channel_mini_help_message', [ !_vm.tariffTag ? _vm.chat.brand.botname : 'ChatKeeperBot']))},slot:"message"})]),_c('div',{staticClass:"text-center mt-2"},[_c('a-button',{attrs:{"type":"primary"},on:{"click":function($event){_vm.isBindModalOpen = true}}},[_vm._v(" "+_vm._s(_vm.$t('bind_channel'))+" ")])],1),_c('bind-channel',{attrs:{"binded-channel-id":_vm.module.model.params.binded_channel_id},on:{"on-bind":_vm.bindFaceControlChannel},model:{value:(_vm.isBindModalOpen),callback:function ($$v) {_vm.isBindModalOpen=$$v},expression:"isBindModalOpen"}})]],2)],2)}
var staticRenderFns = []

export { render, staticRenderFns }